import React from 'react';

import Button from '../../Button';
import './index.css';
import workDots from '../../../img/work-dots.svg';
import { Link } from 'gatsby';

export default () => {
  return (
    <section className="strategy-blue">
      <img
        className="dots"
        src={workDots}
        alt="Dots"
      />
      <div className="container">
        <p className="c-title">
          Your holistic partner for strategy and execution
        </p>
        <p className="body">
          Devetry combines a software consultancy with design and software
          development services. By consolidating work and improving the
          end-to-end development process, we provide a simple, easy partnership
          under one roof.
        </p>
        <Link to="/contact">
          <Button text="Let’s build a strategy" />
        </Link>
      </div>
    </section>
  );
};
