import React, { useRef } from 'react';
import { useCompleteOnScreen } from '../../../utils/hooks';

import { AnimatedDots } from '../../Dots';
import './index.css';
import workDots from '../../../img/work-dots.svg';
import image from '../../../img/blue-section-squiggle-desktop.svg';

export default () => {
  const ref = useRef();
  const completeOnScreen = useCompleteOnScreen(ref);
  return (
    <section ref={ref} className="strategy-second secondary-padding"
      style={{ backgroundImage: `url(${image})`}}
    >
      <div>
        <img
          className="right-dots"
          src={workDots}
          alt="Dots"
        />
        <p className="c-title">
          Whatever stage of growth you’re in, we can help you strategize!
        </p>
        <AnimatedDots completeOnScreen={completeOnScreen} />
        <p className="subtitle">Early State</p>
        <p className="body">
          Getting your digital product to market while minimizing costs and
          maximizing value is what we do. Our product expertise and deep
          technical aptitude provide you an accelerated path to define your
          product roadmap and implement a phased, test-driven plan for your
          market.
        </p>
        <div className="items">
          <ul>
            <li>
              <p>Rapid prototyping</p>
            </li>
          </ul>
          
          <ul>
            <li>
              <p>Technology leadership & culture</p>
            </li>
          </ul>
          <ul>
            <li>
            <p>Organizational strategy</p>
            </li>
          </ul>
          <ul>
            <li>
              <p>Technical team structure</p>
            </li>
          </ul>
        </div>
        <p className="subtitle">Growth-stage</p>
        <p className="body">
          As a rapidly growing and evolving business, you need to scale,
          accelerate, and strategize around your organization’s maturity. Our
          experience across multiple industries and technology challenges makes
          us the perfect partner to help you sort if out—and make way for
          non-stop growth.
        </p>
        <div className="items">
          <ul>
            <li>
              <p>Technology roadmaps & architecture design</p>
            </li>
          </ul>
           
          <ul>
            <li>
              <p>Platform scalability & extensibility</p>
            </li>
          </ul>
            
          <ul>
            <li>
              <p>Code reviews</p>
            </li>
          </ul>
            
          <ul>
            <li>
              <p>M&A technical due diligence</p>
            </li>
          </ul>
            
          <ul>
            <li>
              <p>CTO leadership</p>
            </li>
          </ul>
        </div>
        <p className="subtitle">Enterprise-level</p>
        <p className="body">
          Devetry works with Fortune 500s and government 
          agencies to fully leverage technology and transform 
          offerings into digital solutions. Whether you need 
          help with a specific software solution or an organizational 
          overhaul, we’d love to help you navigate.
        </p>
        <div className="items">
          <ul>
            <li>
              <p>Digital transformation</p>
            </li>
          </ul>

          <ul>
            <li>
              <p>DevOps & infrastructure cost management</p>
            </li>
          </ul>

          <ul>
            <li>
              <p>Technical team structure (topology)</p>
            </li>
          </ul>

          <ul>
            <li>
              <p>Process design</p>
            </li>
          </ul>

          <ul>
            <li>
              <p>Platform & organizational KPIs</p>
            </li>
          </ul>
            
          <ul>
            <li>
              <p>Emerging technologies analysis</p>
            </li>
          </ul> 
        </div>
      </div>
    </section>
  );
};
