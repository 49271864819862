import React, { useRef } from 'react';
import { useCompleteOnScreen } from '../../../utils/hooks';

import { AnimatedDots } from '../../Dots';
import './index.css';
import transformation from '../../../img/transform-your-business.svg';

export default () => {
  const ref = useRef();
  const completeOnScreen = useCompleteOnScreen(ref);
  return (
    <section ref={ref} className="strategy-transform">
      <div className="left">
        <p className="c-title">Transform your business</p>
        <AnimatedDots completeOnScreen={completeOnScreen} />
        <p className="body">
          Digital transformation transforms your business from an OEM, CPG, or
          other retail business into a technology-first organization. In order
          to achieve proper digital transformation, we can help you strategize
          around these four areas.
        </p>
        <div className="items">
          <strong>Team and culture</strong>
          <p>Leverage communication, collaboration, and accountability</p>
          <strong>Data</strong>
          <p>Use data to fuel business strategy and measure success</p>
          <strong>Digital products</strong>
          <p>Build custom dashboards, platforms, and cloud-based systems</p>
          <strong>Innovation mindset</strong>
          <p>
            Transform your thinking to innovate and provide real, lasting value
            to your customers
          </p>
        </div>
      </div>
      <img
        src={transformation}
        alt="Transformation"
      />
    </section>
  );
};
