import React, { useEffect, useContext } from 'react';
import PropTypes from 'prop-types';
import { graphql } from 'gatsby';
import { Helmet } from 'react-helmet';

import AppContext from '../../contexts/AppContext';

// Sections
import Hero from '../../components/CircleBackgroundHero';
import Second from '../../components/strategySections/Second';
import Red from '../../components/technologySections/Red';
import Transform from '../../components/strategySections/Transform';
import Blue from '../../components/strategySections/Blue';
import Blogs from '../../components/BestBlogs';
import WorkCard from '../../components/workCards/BigWorkCard';
import './index.css';

const pageTitle =
  'Software Consulting Services | Technology Strategy for CTOs | Devetry';
const pageDescription =
  'Creating a successful software product requires strategic thinking. Our CEO and CTO can help with software consulting services.';

const StrategyPageTemplate = ({ blogs, work }) => {
  const { setActivePage } = useContext(AppContext);

  useEffect(() => {
    setActivePage('services');
  }, [setActivePage]);

  return (
    <>
      <Helmet>
        <title>{pageTitle}</title>
        <meta name="description" content={pageDescription} />
        <meta property="og:title" content={pageTitle} />
      </Helmet>
      <div className="root-container strategy">
        <Hero
          title="Software Strategy"
          subtitle="Crafted using business experience, technology expertise, and an ear
            to the ground."
          body={
            <span>
              Whether you’re rapidly growing your technology business or
              considering new digital offerings, the Devetry team can help you
              strategize for success.
              <br />
              <br /> We’re a diverse team of problem solvers and who thrive on
              creating out-of-the-box solutions for businesses and product
              teams. Our team is stacked with developers, business analysts,
              designers, product managers, strategists, and technology leaders
              with decades of experience.
              <br />
              <br /> Through this experience, teamwork, and grit, we bring an
              effective strategy and go-forward plan to every partnership.
              Because when you succeed, we succeed.
            </span>
          }
          image="software-strategy-hero@2x.png"
        />
        <Second />
        <Red
          title="CTO-as-a-Service"
          body="Need a CTO’s perspective without a full-time CTO resource? Devetry offers CTO-as-a-service to help your organization strategize technology, optimize internal processes, and build a team that aligns with your software goals. Meet our CTO, Allan Wintersieck, and see how your organization can succeed in software."
        />
        <Transform />
        <Blue />
        {work && (
          <div className="strategy-work">
            <WorkCard caseStudy={work} />
          </div>
        )}
        <Blogs items={blogs} title="Refine your strategic skills" />
      </div>
    </>
  );
};

const StrategyPage = ({ data }) => {
  const { edges } = data.blogs;
  const strategyBlogs = edges.map((e) => ({
    readingTime: e.node.fields.readingTime.text,
    slug: e.node.fields.slug,
    ...e.node.frontmatter
  }));

  const work = data.work.edges.length
    ? {
        slug: data.work.edges[0].node.fields.slug,
        ...data.work.edges[0].node.frontmatter
      }
    : null;

  return <StrategyPageTemplate blogs={strategyBlogs} work={work} />;
};

StrategyPage.propTypes = {
  data: PropTypes.shape({
    markdownRemark: PropTypes.shape({
      frontmatter: PropTypes.object
    })
  })
};

export default StrategyPage;

export const pageQuery = graphql`
  query StrategyPageTemplate {
    markdownRemark(frontmatter: { templateKey: { eq: "Strategy/index" } }) {
      frontmatter {
        templateKey
      }
    }
    work: allMarkdownRemark(
      filter: {
        frontmatter: {
          templateKey: { eq: "CaseStudy/index" }
          tags: { in: "Software" }
        }
      }
      limit: 1
      sort: { fields: frontmatter___date, order: DESC }
    ) {
      edges {
        node {
          fields {
            slug
          }
          frontmatter {
            cardImage {
              src {
                childImageSharp {
                  fluid(quality: 100) {
                    ...GatsbyImageSharpFluid
                  }
                }
              }
              alt
            }
            cardTitle
            cardSubtitle
            tags
            heroLogo {
              src {
                childImageSharp {
                  fluid(quality: 100) {
                    ...GatsbyImageSharpFluid
                  }
                }
              }
              alt
            }
          }
        }
      }
    }
    blogs: allMarkdownRemark(
      filter: {
        published: { eq: true },
        frontmatter: {
          templateKey: { eq: "Blog/index" }
          category: { eq: "Leadership" }
        }
      }
      limit: 3
      sort: { fields: frontmatter___date, order: DESC }
    ) {
      edges {
        node {
          fields {
            slug
            readingTime {
              text
            }
          }
          frontmatter {
            title
            date
            category
          }
        }
      }
    }
  }
`;
